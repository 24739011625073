import { cva } from '@mentimeter/ragnar-tailwind-config';

/*
  Note 2023-04-17:
  While a large size looks good on mobile responsive mode in Chrome,
  on an actual smartphone there are browser elements that eat into the
  viewport (such as navigation elements).

  For example, on an iPhone 12 Pro the modal is not contained on the screen
  and overflows in both directions.

  This specific height value is tested on a few various apple devices and
  provides a good enough margin to not overflow on the these devices.

  If you test it yourself and see that you can increase this number
  without overflowing content, then go ahead!
*/
const MOBILE_MAX_HEIGHT_CLASS = 'max-h-[86vh]';

export const variants = cva(
  [
    'fixed',
    'bg',
    'left-1/2',
    'flex',
    'flex-col',
    'items-stretch',
    'overflow-hidden',
    'z-modal',
    'rounded-2xl',
    'focus:outline-none',
    'translate-x-[-50%]',
    'max-w-[calc(100vw_-_theme(spacing.2)_*_2px)]',
    'sm:max-w-[calc(100vw_-_theme(spacing.16)_*_2px)]',
    MOBILE_MAX_HEIGHT_CLASS,
    'sm:max-h-[calc(100vh_-_theme(spacing.16)_*_2px)]',
    'motion-safe:animate-fade-in',
  ],
  {
    variants: {
      size: {
        small: 'w-[500px]',
        medium: 'w-[720px]',
        large: 'w-[1080px]',
      },
      topAligned: {
        true: ['motion-safe:animate-grow-top', 'top-2', 'sm:top-16'],
        false: [
          'motion-safe:animate-grow-center',
          'translate-y-[-50%]',
          'top-1/2',
        ],
      },
    },
    defaultVariants: {
      size: 'medium',
      topAligned: false,
    },
  },
);
